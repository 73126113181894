import { CommonModule } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { WdxIconButtonModule } from '@wdx/shared/components/wdx-icon-button';
import { WdxIconContainerModule } from '@wdx/shared/components/wdx-icon-container';
import { WdxInfoCardModule } from '@wdx/shared/components/wdx-info-card';
import { FilesFacadeService } from '@wdx/shared/infrastructure/file-io';
import {
    FileIndex,
    FileSizePipe,
    FileTypeAsIconPipe,
    UTILS_ICON_DOWNLOAD,
} from '@wdx/shared/utils';
import { filter } from 'rxjs';

@Component({
    selector: 'wdx-document-info',
    templateUrl: './wdx-document-info.component.html',
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        WdxInfoCardModule,
        WdxIconModule,
        WdxIconButtonModule,
        WdxIconContainerModule,
        FileSizePipe,
        FileTypeAsIconPipe,
    ],
})
export class WdxDocumentInfoComponent {
    public image = 'https://picsum.photos/200/300';
    public hasImage = false;
    private _fileIndex?: FileIndex;

    // the fileIndex of the document
    public get fileIndex(): FileIndex | undefined {
        return this._fileIndex;
    }
    @Input() set fileIndex(val: FileIndex | undefined) {
        if (val?.contentType?.includes('image')) {
            this.hasImage = true;
            this.filesFacadeService
                .getFilePreview$(val)
                .pipe(filter(Boolean))
                .subscribe({
                    next: (image) => {
                        this.image = `url(${image?.fileUrl as string})`;
                    },
                });
        }
        this._fileIndex = val;
    }

    // label override (defaults to fileIndex.name)
    @Input() label?: string | null;

    // subtitle override(defaults to fileIndex.sizeInBytes)
    @Input() subtitle?: string;

    // remove container
    @Input() noContainer = false;

    public readonly ICON_DOWNLOAD = UTILS_ICON_DOWNLOAD;

    private filesFacadeService = inject(FilesFacadeService);

    public downloadFile(): void {
        this.filesFacadeService.downloadFile(this.fileIndex as FileIndex);
    }
}
