import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { Comment, SystemEntity } from '@wdx/clmi/clmi-swagger-gen';
import { CrudStatus } from '@wdx/shared/utils';
import * as ChatActions from './chat.actions';

export const CHAT_FEATURE_KEY = 'chat';

export interface Chat {
    entityId: string;
    entityType: SystemEntity;
    status: CrudStatus;
    statusString: string;
    comments: Comment[];
    error?: any;
}

export type ChatState = EntityState<Chat>;

export interface ChatPartialState {
    readonly [CHAT_FEATURE_KEY]: ChatState;
}

export function getId(value: {
    entityId: string;
    entityType: SystemEntity;
}): string {
    return `${value.entityType}-${value.entityId}`;
}

export const chatAdapter: EntityAdapter<Chat> = createEntityAdapter<Chat>({
    selectId: getId,
});

export const initialChatState: ChatState = chatAdapter.getInitialState({});

const reducer = createReducer(
    initialChatState,
    on(ChatActions.loadChat, (state, { entityId, entityType }) =>
        chatAdapter.upsertOne(
            {
                comments: [],
                entityId,
                entityType,
                status: CrudStatus.Loading,
                statusString: CrudStatus[CrudStatus.Loading],
            },
            {
                ...state,
            },
        ),
    ),
    on(
        ChatActions.loadChatSuccess,
        (state, { comments, entityId, entityType }) =>
            chatAdapter.upsertOne(
                {
                    comments,
                    entityId,
                    entityType,
                    status: CrudStatus.Success,
                    statusString: CrudStatus[CrudStatus.Success],
                },
                {
                    ...state,
                },
            ),
    ),
    on(ChatActions.loadChatFailure, (state, { entityId, entityType, error }) =>
        chatAdapter.upsertOne(
            {
                error,
                comments: [],
                entityId,
                entityType,
                status: CrudStatus.Error,
                statusString: CrudStatus[CrudStatus.Error],
            },
            {
                ...state,
            },
        ),
    ),
    on(ChatActions.createComment, (state, { entityId, entityType }) => {
        const id = getId({ entityId, entityType });
        if ((state.ids as string[]).includes(id)) {
            return chatAdapter.upsertOne(
                {
                    comments: [
                        ...(state.entities[getId({ entityId, entityType })]
                            ?.comments || []),
                    ],
                    entityId,
                    entityType,
                    status: CrudStatus.Updating,
                    statusString: CrudStatus[CrudStatus.Updating],
                },
                {
                    ...state,
                },
            );
        }
        return state;
    }),

    on(
        ChatActions.createCommentSuccess,
        (state, { comment, entityId, entityType, resolvesId }) => {
            const existingComments = [
                ...(state.entities[getId({ entityId, entityType })]?.comments ||
                    []),
            ];
            if (resolvesId) {
                const resolvedCommentIndex = existingComments.findIndex(
                    (comment) => comment.id === resolvesId,
                );
                if (resolvedCommentIndex > -1) {
                    existingComments[resolvedCommentIndex] = {
                        ...existingComments[resolvedCommentIndex],
                        resolution: { id: comment.id },
                        isIssue: false,
                    };
                }
            }
            return chatAdapter.upsertOne(
                {
                    comments: [{ ...comment }, ...existingComments],
                    entityId,
                    entityType,
                    status: CrudStatus.Success,
                    statusString: CrudStatus[CrudStatus.Success],
                },
                {
                    ...state,
                },
            );
        },
    ),
    on(
        ChatActions.createCommentFailure,
        (state, { entityId, entityType, error }) =>
            chatAdapter.upsertOne(
                {
                    error,
                    comments: [
                        ...(state.entities[getId({ entityId, entityType })]
                            ?.comments || []),
                    ],
                    entityId,
                    entityType,
                    status: CrudStatus.Error,
                    statusString: CrudStatus[CrudStatus.Error],
                },
                {
                    ...state,
                },
            ),
    ),
);

export function chatReducer(state: ChatState | undefined, action: Action) {
    return reducer(state, action);
}
