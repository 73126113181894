import {
    ProductStatus,
    RelationshipStatus,
    StatusType,
} from '@wdx/clmi/clmi-swagger-gen';
import { Highlight, WdxDateTimeService } from '@wdx/shared/utils';
import { TagData } from '../models/tag-data.model';

export const INACTIVE_TAG: TagData = {
    label: StatusType.Inactive,
    highlight: Highlight.Info,
    shade: 100,
};

export const DRAFT_TAG: TagData = {
    label: ProductStatus.Draft,
    highlight: Highlight.Info,
    shade: 100,
};

export const INACTIVE_RELATIONSHIP_TAG = (tooltip?: string): TagData => {
    return {
        label: RelationshipStatus.Inactive,
        highlight: Highlight.Info,
        shade: 100,
        tooltip: tooltip,
    };
};

export const DRAFT_RELATIONSHIP_TAG: TagData = {
    label: RelationshipStatus.Draft,
    highlight: Highlight.Info,
    shade: 100,
};

export const ALL_TAG_CATEGORIES_LABEL = 'All Tag Categories';
export const PRIMARY_LABEL = 'Primary';

export const PRIMARY_TAG: TagData = {
    label: PRIMARY_LABEL,
    highlight: Highlight.Info,
    shade: 100,
};

export const RELATIONSHIP_STATUS_TAG = (
    wdxDateTimeService: WdxDateTimeService,
    relationshipStatus?: RelationshipStatus,
    date?: string,
) => {
    const tooltip = date
        ? `Since: ${wdxDateTimeService?.convertDateToViewFriendlyFormat(date)}`
        : null;
    switch (relationshipStatus) {
        case RelationshipStatus.Inactive:
            return tooltip
                ? [INACTIVE_RELATIONSHIP_TAG(tooltip)]
                : [INACTIVE_RELATIONSHIP_TAG()];
        case RelationshipStatus.Draft:
            return [DRAFT_RELATIONSHIP_TAG];

        default:
            return [];
    }
};
