/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum UserActionEntity {
  Person = 'Person',
  Organisation = 'Organisation',
  Client = 'Client',
  Intermediary = 'Intermediary',
  Lead = 'Lead',
  Adviser = 'Adviser',
  Article = 'Article',
  Activity = 'Activity',
  Case = 'Case',
  Opportunity = 'Opportunity',
  ClientProduct = 'ClientProduct',
  ProductProvider = 'ProductProvider',
  List = 'List',
  Construct = 'Construct',
  Campaign = 'Campaign',
  Event = 'Event'
}
