import { createAction, props } from '@ngrx/store';
import { PartyRole } from '@wdx/clmi/clmi-swagger-gen';

export const getForParty = createAction(
    '[Related Clients] Get For Party',
    props<{
        partyId: string;
    }>(),
);

export const getForPartySuccess = createAction(
    '[Related Clients] Get For Party Success',
    props<{
        partyId: string;
        relatedClients: PartyRole[];
    }>(),
);

export const getForPartyFailure = createAction(
    '[Related Clients] Get For Party Failure',
    props<{
        partyId: string;
        error: Error;
    }>(),
);
