/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum SystemEntity {
  Organisation = 'Organisation',
  Person = 'Person',
  User = 'User',
  Team = 'Team',
  Appointment = 'Appointment',
  Task = 'Task',
  ActivityParty = 'ActivityParty',
  ElectronicAddress = 'ElectronicAddress',
  PhoneAddress = 'PhoneAddress',
  PostalAddress = 'PostalAddress',
  PartyRole = 'PartyRole',
  PartyRoleAccess = 'PartyRoleAccess',
  Relationship = 'Relationship',
  Email = 'Email',
  PhoneCall = 'PhoneCall',
  WebHook = 'WebHook',
  Construct = 'Construct',
  Process = 'Process',
  WorkItem = 'WorkItem',
  SocialAddress = 'SocialAddress',
  Product = 'Product',
  ClientProductRelationship = 'ClientProductRelationship',
  ClientProduct = 'ClientProduct',
  ProductGroup = 'ProductGroup',
  Mandate = 'Mandate',
  Objective = 'Objective',
  Tag = 'Tag',
  Note = 'Note',
  Channel = 'Channel',
  DocumentAssociation = 'DocumentAssociation',
  Client = 'Client',
  Intermediary = 'Intermediary',
  Salutation = 'Salutation',
  AmlReport = 'AmlReport',
  FeeStructure = 'FeeStructure',
  TimeBooking = 'TimeBooking',
  Notification = 'Notification',
  AmlRisk = 'AmlRisk',
  DataLock = 'DataLock',
  RiskLevel = 'RiskLevel',
  Comment = 'Comment',
  Nudge = 'Nudge',
  NudgeLog = 'NudgeLog',
  FeedNudge = 'FeedNudge',
  Reminder = 'Reminder',
  Adviser = 'Adviser',
  GoalPoint = 'GoalPoint',
  Article = 'Article',
  Letter = 'Letter',
  InvestmentAccount = 'InvestmentAccount',
  BankAccount = 'BankAccount',
  UserView = 'UserView',
  Opportunity = 'Opportunity',
  MailingInstruction = 'MailingInstruction',
  Benchmark = 'Benchmark',
  Case = 'Case',
  AppStatus = 'AppStatus',
  LendingAccount = 'LendingAccount',
  InsuranceAccount = 'InsuranceAccount',
  PotentialDuplicate = 'PotentialDuplicate',
  Engagement = 'Engagement',
  Message = 'Message',
  ArticleDistribution = 'ArticleDistribution',
  DocumentTemplate = 'DocumentTemplate',
  QiStatus = 'QiStatus',
  QueueItem = 'QueueItem',
  CaseTeamMember = 'CaseTeamMember',
  List = 'List',
  Answer = 'Answer',
  Document = 'Document',
  GeneratedDocument = 'GeneratedDocument',
  Feed = 'Feed',
  DocumentRequirementType = 'DocumentRequirementType',
  DocumentRequirement = 'DocumentRequirement',
  Import = 'Import',
  ExternalBankAccount = 'ExternalBankAccount',
  ExternalBankAccountAssociation = 'ExternalBankAccountAssociation',
  CountryAssociation = 'CountryAssociation',
  NationalIdentifier = 'NationalIdentifier',
  OpportunityTeamMember = 'OpportunityTeamMember',
  Region = 'Region',
  Portfolio = 'Portfolio',
  Lead = 'Lead',
  ClientProductAccess = 'ClientProductAccess',
  Export = 'Export',
  EdsMessageBatch = 'EdsMessageBatch',
  EdsMessage = 'EdsMessage',
  MandateGroup = 'MandateGroup',
  DocumentType = 'DocumentType',
  History = 'History',
  Campaign = 'Campaign',
  CampaignResponse = 'CampaignResponse',
  UserLogin = 'UserLogin',
  ViewTile = 'ViewTile',
  ExternalUser = 'ExternalUser',
  Session = 'Session',
  IdentityCheck = 'IdentityCheck',
  IdentityCheckReview = 'IdentityCheckReview',
  UserDashboard = 'UserDashboard',
  ImportLog = 'ImportLog',
  Envelope = 'Envelope',
  Signatory = 'Signatory',
  IdentityMap = 'IdentityMap',
  ProductProvider = 'ProductProvider',
  WealthManagementAccount = 'WealthManagementAccount',
  ProtectionAccount = 'ProtectionAccount',
  FinancialRecord = 'FinancialRecord',
  FinancialRecordSubType = 'FinancialRecordSubType',
  ProductFinancialLink = 'ProductFinancialLink',
  PendingChange = 'PendingChange',
  PensionAccount = 'PensionAccount',
  ListMember = 'ListMember',
  ExternalTask = 'ExternalTask',
  Block = 'Block',
  Event = 'Event',
  EventParticipation = 'EventParticipation',
  ElectronicBankingAddress = 'ElectronicBankingAddress',
  PartySystemConnection = 'PartySystemConnection',
  EmploymentHistory = 'EmploymentHistory',
  InsiderHistory = 'InsiderHistory',
  KycReport = 'KycReport',
  StockBrokingAccount = 'StockBrokingAccount',
  AppendixEntry = 'AppendixEntry',
  AppendixSchema = 'AppendixSchema',
  AppendixLayout = 'AppendixLayout',
  AppendixCalculation = 'AppendixCalculation',
  Workflow = 'Workflow',
  ScheduledJob = 'ScheduledJob',
  FailedJob = 'FailedJob',
  SystemLog = 'SystemLog',
  AuditEvent = 'AuditEvent',
  Country = 'Country',
  Currency = 'Currency',
  ProductType = 'ProductType',
  RoleType = 'RoleType',
  RoleSubType = 'RoleSubType',
  ContactPoint = 'ContactPoint',
  Activity = 'Activity',
  Party = 'Party'
}
